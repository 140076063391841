import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IComplain } from 'Util/Interface'
import { toast } from 'sonner'

const Complain: React.FC = () => {
  const [tableData, setTableData] = useState<IComplain[]>([])

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/complain`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: IComplain) => {
    try {
      const response = await Http(`/complain/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IComplain> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Full Name',
      key: 'fullName',
      dataIndex: 'fullName',
      render: (text: string) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
      render: (text: string) => {
        return <span>+91 {text}</span>
      },
    },
    {
      title: 'Subject',
      key: 'subject',
      dataIndex: 'subject',
    },
    {
      title: 'Complain',
      key: 'complain',
      dataIndex: 'complain',
    },
    {
      title: 'Complain By',
      key: 'complaintBy',
      dataIndex: 'complaintBy',
    },
    {
      title: 'Active',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: IComplain) => {
        return (
          <Space>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card className="card-wrapper" title={'Complain List'} style={{ padding: '1.25rem 1.25rem 0' }}>
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IComplain) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
    </>
  )
}

export default Complain
