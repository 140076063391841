import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'

import { type AxiosError } from 'axios'

import { useAppDispatch } from 'Hooks/ReduxHook'

import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IDriver } from 'Util/Interface'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

const Drivers: React.FC = () => {
  const [tableData, setTableData] = useState<IDriver[]>([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/driver`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: IDriver) => {
    try {
      const response = await Http(`/driver/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const columns: ColumnsType<IDriver> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'First Name',
      key: 'firstName',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
      render: (value) => <span>+91{value}</span>,
    },
    {
      title: 'Image',
      key: 'profileImage',
      dataIndex: 'profileImage',
      render: (value: string) => {
        return <img src={`${ATTCHMENT_BASE_URL}/${value}`} alt="" height={60} width={60} />
      },
    },
    {
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender',
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
    },
    // {
    //   title: 'Area',
    //   key: 'area',
    //   dataIndex: 'area',
    // },
    // {
    //   title: 'City',
    //   key: 'city',
    //   dataIndex: 'city',
    // },
    // {
    //   title: 'State',
    //   key: 'state',
    //   dataIndex: 'state',
    // },
    // {
    //   title: 'Country',
    //   key: 'country',
    //   dataIndex: 'country',
    // },
    // {
    //   title: 'Bank Name',
    //   key: 'bankName',
    //   dataIndex: 'bankName',
    // },
    // {
    //   title: 'Bank Location',
    //   key: 'bankLocation',
    //   dataIndex: 'bankLocation',
    // },
    // {
    //   title: 'Account No',
    //   key: 'accountNo',
    //   dataIndex: 'accountNo',
    // },
    // {
    //   title: 'IFSC Code',
    //   key: 'ifscCode',
    //   dataIndex: 'ifscCode',
    // },
    // {
    //   title: 'Account Holder Name',
    //   key: 'accountHolder',
    //   dataIndex: 'accountHolder',
    // },
    // {
    //   title: 'Aadhar Card',
    //   key: 'driverAadharCard',
    //   dataIndex: 'driverAadharCard',
    //   render: (value: string) => {
    //     return <img src={`${ATTCHMENT_BASE_URL}/${value}`} alt="" height={60} width={60} />
    //   },
    // },
    // {
    //   title: 'Pan Card',
    //   key: 'driverPanCard',
    //   dataIndex: 'driverPanCard',
    //   render: (value: string) => {
    //     return <img src={`${ATTCHMENT_BASE_URL}/${value}`} alt="" height={60} width={60} />
    //   },
    // },
    {
      title: 'Active',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      render: (_text, record: IDriver) => {
        return (
          <Space>
            <Button
              onClick={() => {
                navigate(`/driver/view/${record.id}`)
              }}
            >
              View
            </Button>
            <Button
              onClick={() => {
                navigate(`/driver/edit/${record.id}`)
              }}
            >
              Update
            </Button>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Drivers List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    navigate('/driver/add')
                  }}
                >
                  Add Driver
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IDriver) => dataIndex?.id} bordered size="middle" />
        </Card>
      </div>
    </>
  )
}

export default Drivers
