import React from 'react'
// import mapImg from '../Assets/images/map.svg'
import { Card } from 'antd'

const DriverLocation: React.FC = () => {
  return (
    <>
      <div className="content-wrapper">
        <Card className="card-wrapper" title={'Driver Location'} style={{ padding: '1.25rem 1.25rem 0' }}>
          {/* <img src={mapImg} alt="" style={{ width: '100%' }} /> */}
        </Card>
      </div>
    </>
  )
}

export default DriverLocation
