import React, { useCallback, useEffect, useState } from 'react'
import { Card, Tag } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { type IBooking } from 'Util/Interface'
import { toast } from 'sonner'
import dayjs from 'dayjs'

const Booking: React.FC = () => {
  const [tableData, setTableData] = useState<IBooking[]>([])

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/booking`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  console.log('tableData', tableData)

  const columns: ColumnsType<IBooking> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Trip Type',
      key: 'tripType',
      dataIndex: 'tripType',
    },
    {
      title: 'Name',
      key: 'fullName',
      dataIndex: 'fullName',
      render: (text: string) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'Pickup Location',
      key: 'pickupLocation',
      dataIndex: 'pickupLocation',
    },
    {
      title: 'Drop Location',
      key: 'dropLocation',
      dataIndex: 'dropLocation',
    },
    {
      title: 'Pickup Date',
      key: 'pickupDate',
      dataIndex: 'pickupDate',
    },
    {
      title: 'Pickup Time',
      key: 'pickupTime',
      dataIndex: 'pickupTime',
    },
    {
      title: 'Booking Type',
      key: 'bookingType',
      dataIndex: 'bookingType',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: 'Booking Date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => <>{dayjs(value).format('DD-MM-YYYY HH:mm')}</>,
    },
    {
      title: 'Booking Status',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Process' ? 'red' : value === 'Failed' ? 'red' : 'green'}>{value}</Tag>,
    },
    {
      title: 'Transaction Id',
      key: 'transid',
      dataIndex: 'transid',
      render: (value, record: any) => <>{record?.transaction?.paymentId}</>,
    },
    {
      title: 'Payment Status',
      key: 'payment_status',
      dataIndex: 'payment_status',
      render: (value, record: any) => <Tag color={record?.transaction?.payment_status === 'Confirm' ? 'green' : 'red'}>{record?.transaction?.payment_status}</Tag>,
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Booking List</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IBooking) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
    </>
  )
}

export default Booking
