import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input, Select, DatePicker, Col, Row, Space, Image } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { useNavigate, useParams } from 'react-router-dom'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { toast } from 'sonner'

const VehicleDetails: React.FC = () => {
  const [registerDoc, setRegisterDoc] = useState('')
  const [insuranceDoc, setInsuranceDoc] = useState('')
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const params = useParams()
  const navigate = useNavigate()

  const fetchById = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/vehicle/${recordId}`)
        if (response.data?.status === 'success' && response?.data?.code === 200) {
          dispatch(setLoader(false))
          if (response.data?.data?.registrationDoc) {
            setRegisterDoc(response.data?.data?.registrationDoc)
          }

          if (response.data?.data?.insuranceDoc) {
            setInsuranceDoc(response.data?.data?.insuranceDoc)
          }
          form.setFieldsValue({
            vehicleName: response.data?.data?.vehicleName,
            vehicleType: response.data?.data?.vehicle?.name,
            // capacity: response.data?.data?.capacity,
            vehicleNo: response.data?.data?.vehicleNo,
            registrationNo: response.data?.data?.registrationNo,
            registrationStartDate: dayjs(response.data?.data?.registrationStartDate),
            registrationEndDate: dayjs(response.data?.data?.registrationEndDate),
            insuranceNo: response.data?.data?.insuranceNo,
            insuranceStartDate: dayjs(response.data?.data?.insuranceStartDate),
            insuranceEndDate: dayjs(response.data?.data?.insuranceEndDate),
            vehicleFitness: response.data?.data?.vehicleFitness,
            status: response.data?.data?.status,
          })
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch, form]
  )

  useEffect(() => {
    if (params?.id) {
      void fetchById(params?.id)
    }
  }, [fetchById, params?.id])

  const handleDelete = async () => {
    try {
      const response = await Http(`/vehicle/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        navigate('/vehicle')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Vechicle Detail</h3>
              </div>
              <div>
                <Space>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/vehicle')
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    danger
                    icon={<PlusOutlined />}
                    onClick={() => {
                      void handleDelete()
                    }}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Form form={form} layout="vertical" autoComplete="off" requiredMark="optional">
            <Row gutter={[40, 6]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Vehicle Name" name="vehicleName">
                  <Input className="custom-input" disabled placeholder="Enter Vehicle Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Car Type" name="vehicleType">
                  <Input className="custom-input" disabled placeholder="Car Type" />
                </Form.Item>
              </Col>
              {/* <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Capacity" name="capacity">
                  <InputNumber className="custom-input-number" min={0} disabled placeholder="Enter Capacity" />
                </Form.Item>
              </Col> */}
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Vehicle Number" name="vehicleNo">
                  <Input className="custom-input" disabled placeholder="Enter Vehicle Number" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Registration Document" name="registrationDoc">
                  {registerDoc ? <Image width={100} src={`${ATTCHMENT_BASE_URL}/${registerDoc}`} /> : <div style={{ background: 'grey', height: 100, width: 100 }} />}
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Registration No" name="registrationNo">
                  <Input className="custom-input" disabled placeholder="Enter Registration No" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Registration Date" name="registrationStartDate">
                  <DatePicker className="custom-date" format={'YYYY-MM-DD'} disabled />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Registration Expire Date" name="registrationEndDate">
                  <DatePicker className="custom-date" format={'YYYY-MM-DD'} disabled />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Insurance Document" name="insuranceDoc">
                  {insuranceDoc ? <Image width={100} src={`${ATTCHMENT_BASE_URL}/${insuranceDoc}`} /> : <div style={{ background: 'grey', height: 100, width: 100 }} />}
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Insurance No" name="insuranceNo">
                  <Input className="custom-input" disabled placeholder="Enter Insurance No" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Insurance Start Date" name="insuranceStartDate">
                  <DatePicker className="custom-date" disabled format={'YYYY-MM-DD'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Insurance Expire Date" name="insuranceEndDate">
                  <DatePicker className="custom-date" disabled format={'YYYY-MM-DD'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Vehicle Fitness" name="vehicleFitness">
                  <Input className="custom-input" disabled placeholder="Enter Vehicle Fitness" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Status" name="status">
                  <Select
                    placeholder="Select Status"
                    className="custom-select"
                    disabled
                    options={[
                      { value: 'Active', label: 'Active' },
                      { value: 'InActive', label: 'InActive' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default VehicleDetails
