import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Form, InputNumber, Modal, Select, Upload, Input, type UploadProps, Image, Row } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAddPackage, type IPackage } from 'Util/Interface'
import { toast } from 'sonner'
import TextArea from 'antd/es/input/TextArea'

const Package: React.FC = () => {
  const [tableData, setTableData] = useState<IPackage[]>([])
  const [carData, setCarData] = useState([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')
  const [uploadImg, setUploadImg] = useState<any>('')
  const [updateUploadImg, setUpdateUploadImg] = useState<any>('')

  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/package`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  const fetchMaster = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/master`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setCarData(response.data?.data?.car)
      } else {
        setCarData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setCarData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
    void fetchMaster()
  }, [fetch, fetchMaster])

  const handleDelete = async (params: IPackage) => {
    try {
      const response = await Http(`/package/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (param: IAddPackage) => {
    try {
      const formValue = new FormData()
      formValue.append('title', param.title)
      formValue.append('amount', String(param.amount))
      formValue.append('perPassengerAmount', String(param.perPassengerAmount))
      formValue.append('note', param.note)
      formValue.append('description', param.description)
      formValue.append('carId', param.carId)
      formValue.append('status', param.status)

      if (param?.packageImage) {
        formValue.append('packageImage', param?.packageImage[0].originFileObj)
      }

      const response = await Http({
        url: !isUpdated ? '/package' : `/package/${updateId}`,
        method: HTTP_METHOD.POST,
        data: formValue,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        form.resetFields()
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        setUploadImg('')
        setUpdateUploadImg('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpdate = (param: IPackage) => {
    setIsUpdated(true)
    setUpdateId(param.id)
    form.setFieldsValue({
      title: param.title,
      note: param.note,
      description: param.description,
      carId: param.carId,
      amount: param.amount,
      perPassengerAmount: param.perPassengerAmount,
      status: param.status,
    })
    setUpdateUploadImg(param.packageImage)
    setVisble(true)
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUploadImg('')
    setUpdateId('')
    setUpdateUploadImg('')
    form.resetFields()
  }

  const columns: ColumnsType<IPackage> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Package Title',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Image',
      key: 'packageImage',
      dataIndex: 'packageImage',
      render: (value: string) => {
        return <img src={`${ATTCHMENT_BASE_URL}/${value}`} alt="" height={60} width={60} />
      },
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: 'Per Passenger Amount',
      key: 'perPassengerAmount',
      dataIndex: 'perPassengerAmount',
    },
    {
      title: 'Note',
      key: 'note',
      dataIndex: 'note',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: 400,
    },
    {
      title: 'Car',
      key: 'car_data',
      dataIndex: 'car_data',
      render: (_, data: IPackage) => <>{data?.car?.name}</>,
    },
    {
      title: 'Active',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      render: (_text, record: IPackage) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleUpdate(record)
              }}
            >
              Update
            </Button>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const props: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Package List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add Package
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: IPackage) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <Modal
        title={!isUpdated ? 'Add Price List' : 'Update Price List'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(value: IPackage) => {
            void handleUpsert(value)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="Package Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please Enter Package Title!',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Package Title" />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please Enter Amount!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Amount" />
          </Form.Item>

          <Form.Item
            label="Per Passenger Amount"
            name="perPassengerAmount"
            rules={[
              {
                required: true,
                message: 'Please Enter Per Passenger Amount!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Per Passenger Amount" />
          </Form.Item>

          <Form.Item
            label="Package Notes"
            name="note"
            rules={[
              {
                required: true,
                message: 'Please Enter Package Notes!',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Package Notes" />
          </Form.Item>

          <Form.Item
            label="Package Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please Enter Package Description!',
              },
            ]}
          >
            <TextArea className="custom-input" placeholder="Enter Package Description" />
          </Form.Item>

          <Form.Item
            label="Car"
            name="carId"
            rules={[
              {
                required: true,
                message: 'Select Car!',
              },
            ]}
          >
            <Select placeholder="Select Car" className="custom-select">
              {carData?.map((ele: any, idx) => {
                return (
                  <Select.Option value={ele?.id} key={idx}>
                    {ele?.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              className="custom-select"
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Package Image"
            name="packageImage"
            rules={[
              {
                required: !isUpdated,
                message: 'Please Upload Image!',
              },
            ]}
            valuePropName="fileList"
            getValueFromEvent={(event) => {
              return event?.fileList
            }}
          >
            <Upload {...props}>{uploadImg?.name ? <Button>{String(uploadImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
          </Form.Item>
          <Row justify={'center'} className="mb-5">
            {updateUploadImg && isUpdated && <Image width={100} src={`${ATTCHMENT_BASE_URL}/${String(updateUploadImg)}`} />}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default Package
