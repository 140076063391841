import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input, Select, Upload, DatePicker, Col, Row, Space, type UploadProps, Image } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAddVehicle, type IVehicle } from 'Util/Interface'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'

const VehicleUpsert: React.FC = () => {
  const [registerImg, setRegisterImg] = useState('')
  const [insuranceImg, setInsuranceImg] = useState('')
  const [uploadRegisterImg, setUploadRegisterImg] = useState<any>('')
  const [uploadInsuranceImg, setUploadInsuranceImg] = useState<any>('')
  const [carData, setCarData] = useState([])
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const params = useParams()
  const navigate = useNavigate()

  const fetchById = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/vehicle/${recordId}`)
        if (response.data?.status === 'success' && response?.data?.code === 200) {
          dispatch(setLoader(false))
          if (response.data?.data?.registrationDoc) {
            setRegisterImg(response.data?.data?.registrationDoc)
          }
          if (response.data?.data?.insuranceDoc) {
            setInsuranceImg(response.data?.data?.insuranceDoc)
          }
          form.setFieldsValue({
            vehicleName: response.data?.data?.vehicleName,
            vehicleType: response.data?.data?.vehicle?.id,
            // capacity: response.data?.data?.capacity,
            vehicleNo: response.data?.data?.vehicleNo,
            registrationNo: response.data?.data?.registrationNo,
            registrationStartDate: dayjs(response.data?.data?.registrationStartDate),
            registrationEndDate: dayjs(response.data?.data?.registrationEndDate),
            insuranceNo: response.data?.data?.insuranceNo,
            insuranceStartDate: dayjs(response.data?.data?.insuranceStartDate),
            insuranceEndDate: dayjs(response.data?.data?.insuranceEndDate),
            vehicleFitness: response.data?.data?.vehicleFitness,
            status: response.data?.data?.status,
          })
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch, form]
  )

  const fetchMaster = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/master`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setCarData(response.data?.data?.car)
      } else {
        setCarData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setCarData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (params?.id) {
      void fetchById(params?.id)
    }
    void fetchMaster()
  }, [fetchById, fetchMaster, params?.id])

  const handleUpsert = async (param: IAddVehicle) => {
    try {
      const formValue = new FormData()
      formValue.append('vehicleName', param.vehicleName)
      formValue.append('vehicleType', param.vehicleType)
      // formValue.append('capacity', String(param.capacity))
      formValue.append('vehicleNo', param.vehicleNo)
      formValue.append('registrationNo', param.registrationNo)
      formValue.append('registrationStartDate', dayjs(param.registrationStartDate).format('YYYY-MM-DD'))
      formValue.append('registrationEndDate', dayjs(param.registrationEndDate).format('YYYY-MM-DD'))
      formValue.append('insuranceNo', param.insuranceNo)
      formValue.append('insuranceStartDate', dayjs(param.insuranceStartDate).format('YYYY-MM-DD'))
      formValue.append('insuranceEndDate', dayjs(param.insuranceEndDate).format('YYYY-MM-DD'))
      formValue.append('vehicleFitness', param.vehicleFitness)
      formValue.append('status', param.status)

      if (param?.registrationDoc) {
        formValue.append('registrationDoc', param?.registrationDoc[0].originFileObj)
      }

      if (param?.insuranceDoc) {
        formValue.append('insuranceDoc', param?.insuranceDoc[0].originFileObj)
      }

      const response = await Http({
        url: !params?.id ? '/vehicle' : `/vehicle/${String(params?.id)}`,
        method: HTTP_METHOD.POST,
        data: formValue,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        if (!params?.id) {
          form.resetFields()
        }
        setUploadRegisterImg('')
        setUploadInsuranceImg('')
        if (params?.id) {
          void fetchById(String(params?.id))
        }
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const registerProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadRegisterImg(info?.file)
    },
  }

  const insuranceProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadInsuranceImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Vechicle Detail</h3>
              </div>
              <div>
                <Space>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/vehicle')
                    }}
                  >
                    Back
                  </Button>
                </Space>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={(value: IVehicle) => {
              void handleUpsert(value)
            }}
            requiredMark="optional"
          >
            <Row gutter={[40, 24]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Vehicle Name"
                  name="vehicleName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Vehicle Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Vehicle Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Car Type"
                  name="vehicleType"
                  rules={[
                    {
                      required: true,
                      message: 'Select Car Type!',
                    },
                  ]}
                >
                  <Select placeholder="Select Car Type" className="custom-select">
                    {carData?.map((ele: any, idx) => {
                      return (
                        <Select.Option value={ele?.id} key={idx}>
                          {ele?.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Capacity"
                  name="capacity"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Capacity!',
                    },
                  ]}
                >
                  <InputNumber className="custom-input-number" min={0} placeholder="Enter Capacity" />
                </Form.Item>
              </Col> */}
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Vehicle Number"
                  name="vehicleNo"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Vehicle Number!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Vehicle Number" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Registration Document"
                      name="registrationDoc"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Document!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...registerProps}>{uploadRegisterImg?.name ? <Button>{String(uploadRegisterImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>{registerImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}/${String(registerImg)}`} />}</Col>
                </Row>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Registration No"
                  name="registrationNo"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Registration No!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Registration No" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Registration Date"
                  name="registrationStartDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Date!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" value={dayjs(dayjs(), 'YYYY-MM-DD')} format={'YYYY-MM-DD'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Registration Expire Date"
                  name="registrationEndDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Date!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" value={dayjs(dayjs(), 'YYYY-MM-DD')} format={'YYYY-MM-DD'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Insurance Document"
                      name="insuranceDoc"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Document!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...insuranceProps}>{uploadInsuranceImg?.name ? <Button>{String(uploadInsuranceImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>{insuranceImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}/${String(insuranceImg)}`} />}</Col>
                </Row>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Insurance No"
                  name="insuranceNo"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Insurance No!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Insurance No" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Insurance Start Date"
                  name="insuranceStartDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Date!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" value={dayjs(dayjs(), 'YYYY-MM-DD')} format={'YYYY-MM-DD'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Insurance Expire Date"
                  name="insuranceEndDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Date!',
                    },
                  ]}
                >
                  <DatePicker className="custom-date" value={dayjs(dayjs(), 'YYYY-MM-DD')} format={'YYYY-MM-DD'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Vehicle Fitness"
                  name="vehicleFitness"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Vehicle Fitness!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Vehicle Fitness" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'Select Status!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Status"
                    className="custom-select"
                    options={[
                      { value: 'Active', label: 'Active' },
                      { value: 'InActive', label: 'InActive' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mt-6 mb-6">
              <Space>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
                <Button
                  danger
                  onClick={() => {
                    navigate('/vehicle')
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default VehicleUpsert
