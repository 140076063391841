import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import Sidebar from './SideBar'
import TopHeader from './TopHeader'
import MainLoader from 'Component/Common/MainLoader'
import { useAppSelector } from 'Hooks/ReduxHook'
import { selectCommon } from 'Store/slices/commonSlice'

const { Content, Sider, Footer } = Layout

const MainLayout = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const commonData = useAppSelector(selectCommon)

  return (
    <>
      {commonData.loading && <MainLoader />}
      <Layout className="main-layout">
        <Sider className={`sidebar-area ${showSidebar ? 'active-sidebar-area' : ''}`}>
          <Sidebar showMenu={setShowSidebar} />
        </Sider>
        <Layout className="content-layout">
          <TopHeader showMenu={setShowSidebar} />
          <Content className="content-area">
            <div>
              <Outlet />
            </div>
            <Footer>
              <div className="footer-text">
                Develop by <span>Kalpatru Yatra</span> in {new Date().getFullYear()} ©.
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

export default MainLayout
