import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input, InputNumber, Select, Col, Row, Space, Image } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { useNavigate, useParams } from 'react-router-dom'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { toast } from 'sonner'

const DriverDetails: React.FC = () => {
  const [profileImg, setProfileImg] = useState('')
  const [aadharImg, setAadharImg] = useState('')
  const [panImg, setPanImg] = useState('')
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const params = useParams()
  const navigate = useNavigate()

  const fetchById = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/driver/${recordId}`)
        if (response.data?.status === 'success' && response?.data?.code === 200) {
          dispatch(setLoader(false))
          if (response.data?.data?.profileImage) {
            setProfileImg(response.data?.data?.profileImage)
          }

          if (response.data?.data?.driverAadharCard) {
            setAadharImg(response.data?.data?.profileImage)
          }

          if (response.data?.data?.driverPanCard) {
            setPanImg(response.data?.data?.driverPanCard)
          }

          form.setFieldsValue({
            firstName: response.data?.data?.firstName,
            lastName: response.data?.data?.lastName,
            email: response.data?.data?.email,
            phone: response.data?.data?.phone,
            // password: response.data?.data?.password,
            // profileImage: response.data?.data?.profileImage.file,
            gender: response.data?.data?.gender,
            address: response.data?.data?.address,
            city: response.data?.data?.city,
            state: response.data?.data?.state,
            country: response.data?.data?.country,
            bankName: response.data?.data?.bankName,
            bankLocation: response.data?.data?.bankLocation,
            accountNo: response.data?.data?.accountNo,
            area: response.data?.data?.area,
            ifscCode: response.data?.data?.ifscCode,
            accountHolder: response.data?.data?.accountHolder,
            // driverAadharCard: response.data?.data?.driverAadharCard.file,
            // driverPanCard: response.data?.data?.driverPanCard.file,
            status: response.data?.data?.status,
          })
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch, form]
  )

  useEffect(() => {
    if (params?.id) {
      void fetchById(params?.id)
    }
  }, [fetchById, params?.id])

  const handleDelete = async () => {
    try {
      const response = await Http(`/driver/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        navigate('/driver')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Driver Detail</h3>
              </div>
              <div>
                <Space>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/driver')
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    danger
                    icon={<PlusOutlined />}
                    onClick={() => {
                      void handleDelete()
                    }}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Form form={form} layout="vertical" autoComplete="off" requiredMark="optional">
            <Row gutter={[40, 8]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="First Name" name="firstName">
                  <Input className="custom-input" disabled placeholder="Enter First Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Last Name" name="lastName">
                  <Input className="custom-input" disabled placeholder="Enter Last Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Email Address" name="email">
                  <Input className="custom-input" disabled placeholder="Enter Email Address" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Phone Number" name="phone">
                  <InputNumber className="custom-input-number" disabled placeholder="Enter Phone Number" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Gender" name="gender">
                  <Select
                    placeholder="Select Gender"
                    className="custom-select"
                    disabled
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Address" name="address">
                  <Input className="custom-input" disabled placeholder="Enter Address" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Area" name="area">
                  <Input className="custom-input" disabled placeholder="Enter Area" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="City" name="city">
                  <Input className="custom-input" disabled placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="State" name="state">
                  <Input className="custom-input" disabled placeholder="Enter State" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Country" name="country">
                  <Input className="custom-input" disabled placeholder="Enter Country" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Bank Name" name="bankName">
                  <Input className="custom-input" disabled placeholder="Bank Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Bank Location" name="bankLocation">
                  <Input className="custom-input" disabled placeholder="Enter Bank Location" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Account Number" name="accountNo">
                  <Input className="custom-input" disabled placeholder="Enter Account Number" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="IFSC Code" name="ifscCode">
                  <Input className="custom-input" disabled placeholder="Enter IFSC Code" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Account Holder Name" name="accountHolder">
                  <Input className="custom-input" disabled placeholder="Enter Account Holder Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Status" name="status">
                  <Select
                    placeholder="Select Status"
                    className="custom-select"
                    disabled
                    options={[
                      { value: 'Active', label: 'Active' },
                      { value: 'InActive', label: 'InActive' },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Driver Aadhar Card" name="driverAadharCard">
                  {aadharImg ? <Image width={100} src={`${ATTCHMENT_BASE_URL}/${aadharImg}`} /> : <div style={{ background: 'grey', height: 100, width: 100 }} />}
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Driver Pan Card" name="driverPanCard">
                  {panImg ? <Image width={100} src={`${ATTCHMENT_BASE_URL}/${panImg}`} /> : <div style={{ background: 'grey', height: 100, width: 100 }} />}
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Driver Image" name="profileImage">
                  {profileImg ? <Image width={100} src={`${ATTCHMENT_BASE_URL}/${profileImg}`} /> : <div style={{ background: 'grey', height: 100, width: 100 }} />}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default DriverDetails
