export interface ILoginUser {
  email: string
  password: string
}

export interface IPagination {
  limit?: number
  skip?: number
  total?: number
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface ICity {
  id: string
  name: string
  code: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddCity {
  id: string
  name: string
  code: string
  status: string
}

export interface IFeedback {
  id: string
  fullName: string
  rating: number
  feedback: string
  status: string
  created_at: string
  updated_at: string
}

export interface IContact {
  id: string
  fullName: string
  email: string
  countryCode: string
  phone: string
  subject: string
  message: string
  status: string
  created_at: string
  updated_at: string
}

export interface IComplain {
  id: string
  fullName: string
  email: string
  countryCode: string
  phone: string
  subject: string
  complain: string
  complaintBy: string
  status: string
  created_at: string
  updated_at: string
}

export interface ICoupon {
  id: string
  title: string
  couponCode: string
  startDate: string
  endDate: string
  usageLimit: number
  discount: number
  status: string
  created_at: string
  updated_at: string
}

export interface ICarType {
  id: string
  name: string
  capacity: number
  bags: number
  carImage: string
  code: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddCarType {
  id: string
  name: string
  capacity: number
  bags: number
  carImage: any
  code: string
  status: string
  created_at: string
  updated_at: string
}

export interface ILocalTrip {
  id: string
  hours: string
  distance: number
  extraDistance: number
  amount: number
  extraAmount: number
  carId: string
  cityId: string
  note: string
  status: string
  created_at: string
  updated_at: string
  car: ICarType
  city: ICity
}

// export interface Car {
//   id: string
//   name: string
//   capacity: number
//   carImage: string
//   status: string
//   created_at: string
//   updated_at: string
// }

// export interface City {
//   id: string
//   name: string
//   code: string
//   status: string
//   created_at: string
//   updated_at: string
// }

export interface IPriceList {
  id: string
  tripType: string
  amount: number
  distance: number
  extraAmount: number
  nightAllowance: number
  note: string
  status: string
  pickupId: string
  dropId: string
  carId: string
  created_at: string
  updated_at: string
  pickup: IPickup
  drop: IDrop
  car: ICar
}

export interface IPickup {
  id: string
  name: string
  code: string
  status: string
  created_at: string
  updated_at: string
}

export interface IDrop {
  id: string
  name: string
  code: string
  status: string
  created_at: string
  updated_at: string
}

export interface ICar {
  id: string
  name: string
  capacity: number
  carImage: string
  status: string
  created_at: string
  updated_at: string
}

// export interface Car {
//   id: string
//   name: string
//   capacity: number
//   carImage: string
//   status: string
//   created_at: string
//   updated_at: string
// }

export interface IPackage {
  id: string
  title: string
  packageImage: string
  amount: number
  perPassengerAmount: number
  note: string
  description: string
  carId: string
  status: string
  created_at: string
  updated_at: string
  car: ICar
}

export interface IAddPackage {
  id: string
  title: string
  packageImage: any
  amount: number
  perPassengerAmount: number
  note: string
  description: string
  carId: string
  status: string
  created_at: string
  updated_at: string
  car: ICar
}

export interface IDriver {
  id: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phone: string
  password: string
  profileImage: string
  gender: string
  address: string
  area: any
  city: string
  state: string
  country: string
  bankName: string
  bankLocation: string
  accountNo: string
  ifscCode: string
  accountHolder: string
  driverAadharCard: string
  driverPanCard: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddDriver {
  id: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phone: string
  password: string
  profileImage: any
  gender: string
  address: string
  area: any
  city: string
  state: string
  country: string
  bankName: string
  bankLocation: string
  accountNo: string
  ifscCode: string
  accountHolder: string
  driverAadharCard: any
  driverPanCard: any
  status: string
  created_at: string
  updated_at: string
}

export interface IVehicle {
  id: string
  vehicleName: string
  vehicleType: string
  capacity: number
  vehicleNo: string
  registrationDoc: string
  registrationNo: string
  registrationStartDate: string
  registrationEndDate: string
  insuranceDoc: string
  insuranceNo: string
  insuranceStartDate: string
  insuranceEndDate: string
  vehicleFitness: string
  status: string
  created_at: string
  updated_at: string
  vehicle: ICar
}

export interface IAddVehicle {
  id: string
  vehicleName: string
  vehicleType: string
  // capacity: number
  vehicleNo: string
  registrationDoc: any
  registrationNo: string
  registrationStartDate: string
  registrationEndDate: string
  insuranceDoc: any
  insuranceNo: string
  insuranceStartDate: string
  insuranceEndDate: string
  vehicleFitness: string
  status: string
  created_at: string
  updated_at: string
  vehicle: ICar
}

export interface IDashboard {
  city: number
  coupon: number
  cars: number
  packages: number
  vehicles: number
  drivers: number
  feedback: number
  complain: number
  contact: number
}

export interface IBooking {
  id: string
  tripType: string
  fullName: string
  email: string
  countryCode: string
  phone: string
  pickupLocation: string
  dropLocation: string
  carId: string
  noOfPassenger: number
  pickupDate: string
  pickupTime: string
  message: string
  bookingType: string
  amount: number
  status: string
  created_at: string
  updated_at: string
}
