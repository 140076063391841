import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import MainLayout from 'Component/MainLayout/MainLayout'
import LoginPage from 'Pages/LoginPage'
import NotFound from 'Pages/NotFound'
import ProfilePage from 'Pages/ProfilePage'
import MainLoader from 'Component/Common/MainLoader'
import CarType from 'Pages/CarType'
import City from 'Pages/City'
import Complain from 'Pages/Complain'
import Contact from 'Pages/Contact'
import Coupon from 'Pages/Coupon'
import DriverLocation from 'Pages/DriverLocation'
import Drivers from 'Pages/Drivers'
import Feedback from 'Pages/Feedback'
import LocalTrip from 'Pages/LocalTrip'
import Package from 'Pages/Package'
import PriceList from 'Pages/PriceList'
import Vehicles from 'Pages/Vehicles'
import VehicleDetails from 'Component/Vehicle/VehicleDetails'
import VehicleUpsert from 'Component/Vehicle/VehicleUpsert'
import DriverDetails from 'Component/Driver/DriverDetails'
import DriverUpsert from 'Component/Driver/DriverUpsert'
import Booking from 'Pages/Booking'

const Dashboard = lazy(async () => await import('Pages/Dashboard'))

// no lazy loading for auth pages to avoid flickering
const Logout = React.lazy(async () => await import('./Logout'))

export const DASHBOARD_PATH = '/'

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  return (
    <BrowserRouter>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path={DASHBOARD_PATH} element={protectedLayout}>
            <Route index element={<Dashboard />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/city" element={<City />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/complain" element={<Complain />} />
            <Route path="/coupon" element={<Coupon />} />
            <Route path="/car" element={<CarType />} />
            <Route path="/local" element={<LocalTrip />} />
            <Route path="/pricelist" element={<PriceList />} />
            <Route path="/package" element={<Package />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/vehicle" element={<Vehicles />} />
            <Route path="/vehicle/add" element={<VehicleUpsert />} />
            <Route path="/vehicle/view/:id" element={<VehicleDetails />} />
            <Route path="/vehicle/edit/:id" element={<VehicleUpsert />} />
            <Route path="/driver" element={<Drivers />} />
            <Route path="/driver/add" element={<DriverUpsert />} />
            <Route path="/driver/view/:id" element={<DriverDetails />} />
            <Route path="/driver/edit/:id" element={<DriverUpsert />} />
            <Route path="/driver-location" element={<DriverLocation />} />
          </Route>

          <Route path="login" element={<LoginPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
