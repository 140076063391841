import React from 'react'
import { CarOutlined, CommentOutlined, CreditCardOutlined, DashboardOutlined, HomeOutlined } from '@ant-design/icons'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'Booking',
    key: 'booking',
    icon: <HomeOutlined />,
    url: '/booking',
  },
  {
    title: 'City',
    key: 'city',
    icon: <HomeOutlined />,
    url: '/city',
  },
  // {
  //   title: 'Coupon',
  //   key: 'coupon',
  //   icon: <AccountBookOutlined />,
  //   url: '/coupon',
  // },
  {
    title: 'Cars Type',
    key: 'car',
    icon: <CarOutlined />,
    url: '/car',
  },
  {
    title: 'Local Trip',
    key: 'local',
    icon: <CarOutlined />,
    url: '/local',
  },
  {
    title: 'Price List',
    key: 'pricelist',
    icon: <CreditCardOutlined />,
    url: '/pricelist',
  },
  {
    title: 'Package',
    key: 'package',
    icon: <CreditCardOutlined />,
    url: '/package',
  },
  // {
  //   title: 'Vehicle',
  //   key: 'vehicle',
  //   icon: <CarOutlined />,
  //   url: '/vehicle',
  // },
  // {
  //   title: 'Driver List',
  //   key: 'driver',
  //   icon: <UserOutlined />,
  //   url: '/driver',
  // },
  // {
  //   title: 'Driver Location',
  //   key: 'location',
  //   url: '/driver-location',
  //   icon: <AreaChartOutlined />,
  // },
  // {
  //   title: 'Feedback',
  //   key: 'feedback',
  //   icon: <CommentOutlined />,
  //   url: '/feedback',
  // },
  {
    title: 'Contact',
    key: 'contact',
    icon: <CommentOutlined />,
    url: '/contact',
  },
  // {
  //   title: 'Complain',
  //   key: 'complain',
  //   icon: <CommentOutlined />,
  //   url: '/complain',
  // },
]
