import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Input, Modal, Select, Form, InputNumber, Upload, type UploadProps, Image, Row } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAddCarType, type ICarType } from 'Util/Interface'
import { toast } from 'sonner'

const CarType: React.FC = () => {
  const [tableData, setTableData] = useState<ICarType[]>([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')
  const [uploadImg, setUploadImg] = useState<any>('')
  const [updateUploadImg, setUpdateUploadImg] = useState<any>('')
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/car`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: ICarType) => {
    try {
      const response = await Http(`/car/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (param: IAddCarType) => {
    try {
      const formData = new FormData()
      formData.append('name', param.name)
      formData.append('code', param.code)
      formData.append('capacity', String(param.capacity))
      formData.append('bags', String(param.bags))
      formData.append('status', param.status)

      if (param?.carImage) {
        formData.append('carImage', param?.carImage[0].originFileObj)
      }

      const response = await Http({
        url: !isUpdated ? '/car' : `/car/${updateId}`,
        method: HTTP_METHOD.POST,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        form.resetFields()
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        setUploadImg('')
        setUpdateUploadImg('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpdate = (param: ICarType) => {
    setIsUpdated(true)
    setUpdateId(param.id)
    form.setFieldsValue({
      name: param.name,
      code: param.code,
      capacity: param.capacity,
      bags: param.bags,
      status: param.status,
    })
    setUpdateUploadImg(param.carImage)
    setVisble(true)
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUploadImg('')
    setUpdateId('')
    setUpdateUploadImg('')
    form.resetFields()
  }

  const columns: ColumnsType<ICarType> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Car Name',
      key: 'name',
      dataIndex: 'name',
      render: (text: string) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Car Code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: 'Capacity',
      key: 'capacity',
      dataIndex: 'capacity',
    },
    {
      title: 'Bags',
      key: 'bags',
      dataIndex: 'bags',
    },
    {
      title: 'Image',
      key: 'carImage',
      dataIndex: 'carImage',
      render: (value: string) => {
        return <img src={`${ATTCHMENT_BASE_URL}/${value}`} alt="" height={60} width={60} />
      },
    },
    {
      title: 'Active',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      render: (_text, record: ICarType) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleUpdate(record)
              }}
            >
              Update
            </Button>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  const props: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Car List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add Car
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: any) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <Modal
        title={!isUpdated ? 'Add Car' : 'Update Car'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(value: ICarType) => {
            void handleUpsert(value)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="Car name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please Enter Car Name!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Car Name" />
          </Form.Item>

          <Form.Item
            label="Car Code"
            name="code"
            rules={[
              {
                required: true,
                message: 'Please Enter Car Code!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Car Code" />
          </Form.Item>

          <Form.Item
            label="Car Capacity"
            name="capacity"
            rules={[
              {
                required: true,
                message: 'Please Enter Car Capacity!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Car Capacity" />
          </Form.Item>

          <Form.Item
            label="Bag Capacity"
            name="bags"
            rules={[
              {
                required: false,
                message: 'Please Enter No of Bags!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter No of Bags" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              className="custom-select"
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Car Image"
            name="carImage"
            rules={[
              {
                required: !isUpdated,
                message: 'Please Upload Image!',
              },
            ]}
            valuePropName="fileList"
            getValueFromEvent={(event) => {
              return event?.fileList
            }}
          >
            <Upload {...props}>{uploadImg?.name ? <Button>{String(uploadImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
          </Form.Item>
          <Row justify={'center'} className="mb-5">
            {updateUploadImg && isUpdated && <Image width={100} src={`${ATTCHMENT_BASE_URL}/${String(updateUploadImg)}`} />}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default CarType
