import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Form, Input, Modal, Select } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAddCity, type ICity } from 'Util/Interface'
import { toast } from 'sonner'

const City: React.FC = () => {
  const [tableData, setTableData] = useState<ICity[]>([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')

  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/city`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: ICity) => {
    try {
      const response = await Http(`/city/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (param: IAddCity) => {
    try {
      // if (updateId) {
      //   Object.assign(param, { id: updateId })
      // }

      const response = await Http({
        url: !isUpdated ? '/city' : `/city/${updateId}`,
        method: !isUpdated ? HTTP_METHOD.POST : HTTP_METHOD.PUT,
        data: param,
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        form.resetFields()
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpdate = (param: ICity) => {
    setIsUpdated(true)
    setUpdateId(param.id)
    form.setFieldsValue({
      name: param.name,
      code: param.code,
      status: param.status,
    })
    setVisble(true)
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUpdateId('')
    form.resetFields()
  }

  const columns: ColumnsType<ICity> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text: string) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: 'Active',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: ICity) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleUpdate(record)
              }}
            >
              Update
            </Button>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>City List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add City
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: ICity) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <Modal
        title={!isUpdated ? 'Add City' : 'Update City'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(value: IAddCity) => {
            void handleUpsert(value)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="City name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please Enter City name!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter City" />
          </Form.Item>

          <Form.Item
            label="City Code"
            name="code"
            rules={[
              {
                required: true,
                message: 'Please Enter City Code!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter City Code" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              className="custom-select"
              style={{ width: '100%' }}
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default City
