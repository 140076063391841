import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Form, Input, InputNumber, Select, Col, Row, Space, Upload, type UploadProps, Image } from 'antd'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import { type IAddDriver } from 'Util/Interface'

const DriverUpsert: React.FC = () => {
  const [aadharImg, setAadharImg] = useState<any>('')
  const [panImg, setPanImg] = useState<any>('')
  const [profileImg, setProfileImg] = useState<any>('')
  const [uploadAadharImg, setUploadAadharImg] = useState<any>('')
  const [uploadPanImg, setUploadPanImg] = useState<any>('')
  const [uploadProfileImg, setUploadProfileImg] = useState<any>('')
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const params = useParams()
  const navigate = useNavigate()

  const fetchById = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/driver/${recordId}`)
        if (response.data?.status === 'success' && response?.data?.code === 200) {
          dispatch(setLoader(false))
          if (response.data?.data?.profileImage) {
            setProfileImg(response.data?.data?.profileImage)
          }
          if (response.data?.data?.driverPanCard) {
            setPanImg(response.data?.data?.driverPanCard)
          }
          if (response.data?.data?.driverAadharCard) {
            setAadharImg(response.data?.data?.driverAadharCard)
          }
          form.setFieldsValue({
            firstName: response.data?.data?.firstName,
            lastName: response.data?.data?.lastName,
            email: response.data?.data?.email,
            phone: response.data?.data?.phone,
            // password: response.data?.data?.password,
            gender: response.data?.data?.gender,
            address: response.data?.data?.address,
            city: response.data?.data?.city,
            state: response.data?.data?.state,
            country: response.data?.data?.country,
            bankName: response.data?.data?.bankName,
            bankLocation: response.data?.data?.bankLocation,
            accountNo: response.data?.data?.accountNo,
            area: response.data?.data?.area,
            ifscCode: response.data?.data?.ifscCode,
            accountHolder: response.data?.data?.accountHolder,
            status: response.data?.data?.status,
          })
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch, form]
  )

  useEffect(() => {
    if (params?.id) {
      void fetchById(params?.id)
    }
  }, [fetchById, params?.id])

  const handleUpsert = async (param: IAddDriver) => {
    try {
      const formValue = new FormData()
      if (param.area) {
        formValue.append('area', param.area)
      }
      formValue.append('firstName', param.firstName)
      formValue.append('lastName', param.lastName)
      formValue.append('email', param.email)
      formValue.append('phone', param.phone)
      formValue.append('password', param.password)
      // formValue.append('profileImage', param.profileImage.file)
      formValue.append('gender', param.gender)
      formValue.append('address', param.address)
      formValue.append('city', param.city)
      formValue.append('state', param.state)
      formValue.append('country', param.country)
      formValue.append('bankName', param.bankName)
      formValue.append('bankLocation', param.bankLocation)
      formValue.append('accountNo', String(param.accountNo))
      formValue.append('ifscCode', param.ifscCode)
      formValue.append('accountHolder', param.accountHolder)
      // formValue.append('driverAadharCard', param.driverAadharCard.file)
      // formValue.append('driverPanCard', param.driverPanCard.file)
      formValue.append('status', param.status)

      if (param?.profileImage) {
        formValue.append('profileImage', param?.profileImage[0].originFileObj)
      }

      if (param?.driverAadharCard) {
        formValue.append('driverAadharCard', param?.driverAadharCard[0].originFileObj)
      }

      if (param?.driverPanCard) {
        formValue.append('driverPanCard', param?.driverPanCard[0].originFileObj)
      }

      const response = await Http({
        url: params?.id ? `/driver/${String(params?.id)}` : '/driver',
        method: 'POST',
        data: formValue,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        if (!params?.id) {
          form.resetFields()
        }
        setUploadAadharImg('')
        setUploadPanImg('')
        setUploadProfileImg('')
        if (params?.id) {
          void fetchById(String(params?.id))
        }
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
      form.resetFields()
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const profileProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadProfileImg(info?.file)
    },
  }

  const aadharProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadAadharImg(info?.file)
    },
  }

  const panProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    maxCount: 1,
    // listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: false,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadPanImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Driver Detail</h3>
              </div>
              <div>
                <Space>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('/driver')
                    }}
                  >
                    Back
                  </Button>
                </Space>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={(value: IAddDriver) => {
              void handleUpsert(value)
            }}
            requiredMark="optional"
          >
            <Row gutter={[40, 24]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter First Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter First Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Last Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Last Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Email Address!',
                      type: 'email',
                    },
                  ]}
                >
                  <Input className="custom-input" disabled={!!params?.id} placeholder="Enter Email Address" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Phone Number!',
                    },
                  ]}
                >
                  <InputNumber className="custom-input-number" disabled={!!params?.id} minLength={10} maxLength={10} placeholder="Enter Phone Number" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: 'Select Gender!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Gender"
                    className="custom-select"
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Address!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Address" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label="Area" name="area">
                  <Input className="custom-input" placeholder="Enter Area" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter City!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter State!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter State" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Country!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Country" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Bank Name"
                  name="bankName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Bank Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Bank Name" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Bank Location"
                  name="bankLocation"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Bank Location!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Bank Location" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Account Number"
                  name="accountNo"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Account Number!',
                    },
                  ]}
                >
                  <InputNumber className="custom-input-number" min={0} placeholder="Enter Account Number" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="IFSC Code"
                  name="ifscCode"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter IFSC Code!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter IFSC Code" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Account Holder Name"
                  name="accountHolder"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Account Holder Name!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Account Holder Name" />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'Select Status!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Status"
                    className="custom-select"
                    options={[
                      { value: 'Active', label: 'Active' },
                      { value: 'InActive', label: 'InActive' },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Driver Aadhar Card"
                      name="driverAadharCard"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Aadhar Card!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...aadharProps}>{uploadAadharImg?.name ? <Button>{String(uploadAadharImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-3">{aadharImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}/${String(aadharImg)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Driver Pan Card"
                      name="driverPanCard"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Pan Card!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...panProps}>{uploadPanImg?.name ? <Button>{String(uploadPanImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{panImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}/${String(panImg)}`} />}</div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={12} sm={24} xs={24}>
                <Row>
                  <Col>
                    <Form.Item
                      label="Driver Image"
                      name="profileImage"
                      rules={[
                        {
                          required: !params?.id,
                          message: 'Please Upload Image!',
                        },
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...profileProps}>{uploadProfileImg?.name ? <Button>{String(uploadProfileImg?.name)}</Button> : <Button> Upload Image </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="ml-4">{profileImg && <Image width={100} src={`${ATTCHMENT_BASE_URL}/${String(profileImg)}`} />}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-6 mb-6">
              <Space>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
                <Button
                  danger
                  onClick={() => {
                    navigate('/driver')
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default DriverUpsert
