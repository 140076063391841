import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Form, Modal, Select, InputNumber } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type ILocalTrip } from 'Util/Interface'
import { toast } from 'sonner'
import TextArea from 'antd/es/input/TextArea'

const LocalTrip: React.FC = () => {
  const [tableData, setTableData] = useState<ILocalTrip[]>([])
  const [carData, setCarData] = useState([])
  const [cityData, setCityData] = useState([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')

  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/local`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  const fetchMaster = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/master`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setCarData(response.data?.data?.car)
        setCityData(response.data?.data?.city)
      } else {
        setCityData([])
        setCarData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setCityData([])
      setCarData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
    void fetchMaster()
  }, [fetch, fetchMaster])

  const handleDelete = async (params: ILocalTrip) => {
    try {
      const response = await Http(`/local/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (param: ILocalTrip) => {
    try {
      // if (updateId) {
      //   Object.assign(param, { id: updateId })
      // }

      const response = await Http({
        url: !isUpdated ? '/local' : `/local/${updateId}`,
        method: !isUpdated ? HTTP_METHOD.POST : HTTP_METHOD.PUT,
        data: param,
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        form.resetFields()
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpdate = (param: ILocalTrip) => {
    setIsUpdated(true)
    setUpdateId(param.id)
    form.setFieldsValue({
      hours: param.hours,
      distance: param.distance,
      extraDistance: param.extraDistance,
      amount: param.amount,
      extraAmount: param.extraAmount,
      carId: param.carId,
      cityId: param.cityId,
      note: param.note,
      status: param.status,
    })
    setVisble(true)
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUpdateId('')
    form.resetFields()
  }

  const columns: ColumnsType<ILocalTrip> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Hours',
      key: 'hours',
      dataIndex: 'hours',
      render: (text: string) => {
        return <span>{text} Hr</span>
      },
    },
    {
      title: 'Distance',
      key: 'distance',
      dataIndex: 'distance',
      render: (text: string) => {
        return <span>{text} Km</span>
      },
    },
    {
      title: 'Extra Distance',
      key: 'extraDistance',
      dataIndex: 'extraDistance',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: 'Extra Amount',
      key: 'extraAmount',
      dataIndex: 'extraAmount',
    },
    {
      title: 'Car',
      key: 'car_data',
      dataIndex: 'car_data',
      render: (_, data: any) => <>{data?.car?.name}</>,
    },
    {
      title: 'City',
      key: 'city_data',
      dataIndex: 'city_data',
      render: (_, data: any) => <>{data?.city?.name}</>,
    },
    {
      title: 'Notes',
      key: 'note',
      dataIndex: 'note',
    },
    {
      title: 'Active',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'Active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      render: (_text, record: ILocalTrip) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleUpdate(record)
              }}
            >
              Update
            </Button>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Local Trip List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add Local Trip
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: ILocalTrip) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <Modal
        title={!isUpdated ? 'Add Local Trip' : 'Update Local Trip'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(value: ILocalTrip) => {
            void handleUpsert(value)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="Hours"
            name="hours"
            rules={[
              {
                required: true,
                message: 'Please Enter Hours!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Hours" />
          </Form.Item>

          <Form.Item
            label="Distance"
            name="distance"
            rules={[
              {
                required: true,
                message: 'Please Enter Distance!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Distance" />
          </Form.Item>

          <Form.Item
            label="Extra Distance Price"
            name="extraDistance"
            rules={[
              {
                required: true,
                message: 'Please Enter Extra Distance!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Extra Distance" />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please Enter Amount!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Amount" />
          </Form.Item>

          <Form.Item
            label="Extra Hour Amount"
            name="extraAmount"
            rules={[
              {
                required: true,
                message: 'Please Enter Extra Amount!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" min={0} placeholder="Enter Extra Amount" />
          </Form.Item>

          <Form.Item
            label="Car"
            name="carId"
            rules={[
              {
                required: true,
                message: 'Select Car!',
              },
            ]}
          >
            <Select placeholder="Select Car" className="custom-select">
              {carData?.map((ele: any, idx) => {
                return (
                  <Select.Option value={ele?.id} key={idx}>
                    {ele?.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            name="cityId"
            rules={[
              {
                required: true,
                message: 'Select City!',
              },
            ]}
          >
            <Select placeholder="Select City" className="custom-select">
              {cityData?.map((ele: any, idx) => {
                return (
                  <Select.Option value={ele?.id} key={idx}>
                    {ele?.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Notes"
            name="note"
            rules={[
              {
                required: false,
                message: 'Please Enter Notes!',
              },
            ]}
          >
            <TextArea className="custom-input-number" placeholder="Enter Notes" />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              className="custom-select"
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'InActive', label: 'InActive' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default LocalTrip
