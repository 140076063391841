export const BASE_URL = 'https://api.kalptaruyatra.com/api/v1'
export const ATTCHMENT_BASE_URL = 'https://api.kalptaruyatra.com'

// export const BASE_URL = 'http://127.0.0.1:8000/api/v1'
// export const ATTCHMENT_BASE_URL = 'http://127.0.0.1:8000'

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const

const getMedia = <T extends number>(breakpoint: T): `(min-width: ${T}px)` => `(min-width: ${breakpoint}px)`

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
}

export const arrayFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}
